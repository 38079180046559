<template>
  <div id="app" v-loading="loading"
       element-loading-text="拼命识别中..."
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item index="1">物品识别</el-menu-item>
      <el-menu-item index="2">文字识别</el-menu-item>
      <el-menu-item index="3">AI对话</el-menu-item>
    </el-menu>
    <br>
    <div v-if="activeIndex=='1' || activeIndex=='2'">
      <el-upload
              :file-list="fileList"
              class="upload"
              drag
              list-type="picture"
              :on-change="handleChange"
              :auto-upload="false"
              accept=".jpg,.jpeg,.png"
              action="#">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传jpg/jpeg/png文件</div>
      </el-upload>

      <div v-if="retList.length <= 0 && fileList.length > 0 && !loading">
        <el-divider></el-divider>
        <el-button type="warning" plain @click="reUpload">重新识别<i class="el-icon-refresh"></i></el-button>
      </div>

      <el-divider v-if="retList.length > 0">识别结果</el-divider>

      <el-collapse v-for="ret in retList" :key="ret.keyword" v-model="activeNames" v-if="activeIndex == '1'">
        <el-progress :text-inside="true" :stroke-width="24" :percentage="ret.score" :status="ret.status"></el-progress>
        <el-collapse-item :name="ret.score">
          <template slot="title">
            {{ret.keyword+'('+ret.root+')'}}<i class="header-icon el-icon-info" v-if="ret.baike_info && ret.baike_info.baike_url"></i>
          </template>
          <div v-if="ret.baike_info && ret.baike_info.description">{{ret.baike_info.description}}</div>
          <el-link type="primary" v-if="ret.baike_info && ret.baike_info.baike_url" target="_blank" :href="ret.baike_info.baike_url">详情<i class="el-icon-view el-icon--right"></i></el-link>
        </el-collapse-item>
      </el-collapse>

      <el-card class="box-card" v-if="activeIndex == '2' && retList.length > 0">
        <div v-for="ret in retList" :key="ret.keyword" class="text item" style="text-align: left;">
          {{ret.keyword}}
        </div>
      </el-card>
    </div>

    <div class="chat-box" v-if="activeIndex=='3'">
      <div class="message-container" ref="messageContainer">
        <div v-for="(message, index) in messages" :key="index" :class="['message', message.isAi ? 'ai' : 'user']">
          <img v-if="message.isAi" src="https://ai.songm.top/favicon-32x32.png" class="avatar" />
          <div class="message-content" :class="{'ai-reply-content': message.isAi}" v-html="renderMarkdown(message.text, message.isAi)"></div>
        </div>
      </div>
      <div class="input-box">
        <input type="file" ref="attachmentInput" @change="handleAttachmentChange" style="display: none" />
        <button @click="openAttachmentInput" class="attachment-button">
          <i v-if="!attachmentUploaded" class="el-icon-paperclip"></i>
          <i v-else class="el-icon-circle-check"></i>
        </button>
        <input v-model="newMessage" @keydown.enter="sendMessage" placeholder="请输入问题..." class="message-input" />
        <button @click="sendMessage" class="send-button" :disabled="sendingMessage">
          <i v-if="!sendingMessage" class="fa fa-paper-plane"></i> 发送
          <i v-if="sendingMessage" class="el-icon-loading"></i>
        </button>
      </div>
      <div v-if="retList.length <= 0 && attachmentUploaded && !loading">
        <el-divider></el-divider>
        <el-button type="warning" plain @click="reUpload">重新识别<i class="el-icon-refresh"></i></el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import MarkdownIt from 'markdown-it';
  export default {
    data() {
      return {
        shiTuUrl: 'https://ts-api.songm.top/upload',
        // shiTuUrl: 'http://localhost:4000/upload',
        chatUrl: 'https://free.churchless.tech/v1/chat/completions',
        fileList: [],
        retList: [],
        loading: false,
        dialogImageUrl: '',
        activeNames: 0,
        activeIndex: '1',
        messages: [],
        newMessage: '',
        attachmentUploaded: false, // 附件是否已上传标志
        sendingMessage: false,
      }
    },
    created() {
      document.title = 'Ai Tools';
    },
    methods: {
      handleChange(file, fileList) {
        this.fileList = fileList;
        if (file.size > 4 * 1024 * 1024) {
          this.compressImg(file.raw);
        }else{
          this.upload(file.raw);
        }
      },
      reUpload() {
        this.upload(this.fileList[0].raw ? this.fileList[0].raw : this.fileList[0]);
      },
      upload(file) {
        this.loading = true;
        let fd = new FormData();
        fd.append("mimeType", file.type);
        fd.append("file", file);
        fd.append("activeIndex", this.activeIndex == '3' ? '2' : this.activeIndex);
        axios.post(this.shiTuUrl,fd).then(res=>{
          this.loading = false;
          this.retList = [];
          this.attachmentUploaded = true;
          this.fileList = this.fileList.length > 1 ? [this.fileList[this.fileList.length - 1]] : this.fileList;
          if (this.fileList.length < 1) this.fileList = [file];
          if (res.status == 200) {
            if (res.data.errno === 0) {
              this.activeNames = 0;
              if (this.activeIndex == '1') {
                res.data.data.result.forEach(item => {
                  let score = item.score * 100;
                  let it = {
                    keyword: item.keyword,
                    score: score,
                    status: null,
                    root: item.root,
                    baike_info: item.baike_info
                  };
                  if (score >= 75) {
                    it.status = 'success';
                  } else if (score >= 50) {
                  } else if (score >= 25) {
                    it.status = 'warning';
                  } else {
                    it.status = 'exception';
                  }
                  if (this.activeNames == 0) this.activeNames = item.baike_info && item.baike_info.baike_url ? score : 0;
                  this.retList.push(it);
                });
              } else if (this.activeIndex == '2' || this.activeIndex == '3') {
                res.data.data.results.forEach(item => {
                  this.retList.push({
                    keyword: item.words.word
                  });
                });
              }
              this.$message('识别成功');
            }else{
              this.$message.error(res.data.msg);
            }
          } else {
            this.$message.error(res.statusText);
          }
        }).catch((err) => {
          this.loading = false;
          this.retList = [];
          this.fileList = this.fileList.length > 1 ? [this.fileList[this.fileList.length - 1]] : this.fileList;
          console.log(err);
          this.$message.error('识别出错');
        });
      },
      /**
       * @压缩公共方法
       * @params file
       * @return 压缩后的文件，支持两种，file和 blob
       */
      compressImg(file) {
        const reader = new FileReader();
        // readAsDataURL 方法会读取指定的 Blob 或 File 对象。读取操作完成的时候，readyState 会变成已完成DONE，并触发 loadend (en-US) 事件，
        // 同时 result 属性将包含一个data:URL格式的字符串（base64编码）以表示所读取文件的内容。
        reader.readAsDataURL(file);
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result;
          img.onload = () => {
            // 图片的宽高
            const w = img.width;
            const h = img.height;
            const canvas = document.createElement("canvas");
            // canvas对图片进行裁剪，这里设置为图片的原始尺寸
            canvas.width = w;
            canvas.height = h;
            const ctx = canvas.getContext("2d");
            // canvas中，png转jpg会变黑底，所以先给canvas铺一张白底
            ctx.fillStyle = "#fff";
            // fillRect()方法绘制一个填充了内容的矩形，这个矩形的开始点（左上点）在
            // (x, y) ，它的宽度和高度分别由width 和 height 确定，填充样式由当前的fillStyle 决定。
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            // 绘制图像
            ctx.drawImage(img, 0, 0, w, h);

            // canvas转图片达到图片压缩效果
            // 返回一个包含图片展示的 data URI base64 在指定图片格式为 image/jpeg 或 image/webp的情况下，
            // 可以从 0 到 1 的区间内选择图片的质量。如果超出取值范围，将会使用默认值 0.92。其他参数会被忽略。
            const dataUrl = canvas.toDataURL("image/jpeg", 0.5);
            this.dialogImageUrl = dataUrl

            // base64格式文件转成Blob文件格式
            // let blobFile = this.dataURLtoBlob(dataUrl);
            // console.log("压缩后的图片：Blob文件----------");
            // console.log(blobFile);
            // base64格式文件转成file文件格式
            let fileName = file.name;
            let fileImg = this.dataURLtoFile(dataUrl,fileName);
            // console.log("压缩后的图片：file文件----------");
            // console.log(fileImg);
            this.upload(fileImg);
          };
        };
      },
      // canvas生成的格式为base64，需要进行转化, base64->file
      dataURLtoFile(dataurl,fileName) {
        let arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],

                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, {type:mime})
      },
      // canvas生成的格式为base64，需要进行转化, base64->blob
      dataURLtoBlob(dataurl) {
        const arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      },
      handleSelect(idx) {
        this.activeIndex = idx;
        this.retList = [];
        this.fileList = [];
        this.attachmentUploaded = false;
      },
      sendMessage() {
        if (this.newMessage.trim().length <= 0) return;
        const userMessage = {
          text: this.newMessage,
          isAi: false,
        };
        this.messages.push(userMessage);
        this.sendingMessage = true;

        let sendMsg = [];
        let text = '';
        if (this.retList.length > 0) {
          text += '以下是图片识别出的内容\n\n';
          this.retList.forEach(item => {
            text += item.keyword+'\n';
          });
          sendMsg.push({
            role: 'user',
            content: text
          });
        }

        let maxLength = 3600;
        for (let i = 0; i < this.messages.length; i++) {
          let txt = this.messages[i].text;
          if ((text+txt).length > maxLength) {
            sendMsg.splice(0, 1);
          }
          text += txt;
          sendMsg.push({
            role: this.messages[i].isAi ? 'assistant' : 'user',
            content: txt
          });
        }

        axios.post(this.chatUrl,{"messages":sendMsg,"model":"gpt-3.5-turbo","temperature":0.7,"presence_penalty":0,"top_p":1,"frequency_penalty":0,"stream":false}).then(res=>{
          if (res.status == 200) {
            //console.log(res)
            const aiReply = {
              text: res.data.choices[0].message.content,
              isAi: true,
            };
            this.messages.push(aiReply);
            this.sendingMessage = false;
          }
        }).catch((err) => {
          console.log(err);
          this.$message.error('出错');
          this.sendingMessage = false;
        });

        this.newMessage = '';

        // 滚动消息容器到底部以显示新消息
        // this.$nextTick(() => {
        //   this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
        // });
      },
      openAttachmentInput() {
        this.$refs.attachmentInput.click();
      },
      handleAttachmentChange(event) {
        const selectedFile = event.target.files[0];
        // 在这里处理选中的附件文件，例如上传至服务器等
        if (selectedFile && selectedFile.size && selectedFile.size > 4 * 1024 * 1024) {
          this.compressImg(selectedFile);
        }else{
          this.upload(selectedFile);
        }
      },
      renderMarkdown(text, isAi) {
        const md = new MarkdownIt();
        const renderedText = md.render(text);
        if (isAi) {
          return `<div class="ai-reply">${renderedText}</div>`;
        } else {
          return renderedText;
        }
      }
    }
  }
</script>

<style>
  #app {
    font-family: Helvetica, sans-serif;
    text-align: center;
  }

  .chat-box {
    width: 100%;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .message-container {
    max-height: calc(70vh);
    overflow-y: auto;
    padding: 10px;
  }

  .message {
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .message.ai {
    display: flex;
  }

  .ai, .user {
    /*max-width: 80%;*/
    padding: 8px;
    border-radius: 8px;
    text-align: right;
    padding-right: 10px;
  }

  .ai {
    background-color: #e1f5fe;
    margin-right: auto;
  }

  .user {
    background-color: #e0e0e0;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .input-box {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ccc;
  }

  .input-box input {
    flex: 1;
    padding: 8px;
    border: none;
    border-radius: 4px;
    outline: none;
  }

  .input-box button {
    margin-left: 10px;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    outline: none;
  }

  .input-box button:hover {
    background-color: #0056b3;
  }

  .attachment-button {
    padding: 8px;
    border: none;
    background-color: transparent;
    color: #007bff;
    cursor: pointer;
    outline: none;
  }

  .message-input {
    flex: 1;
    padding: 8px;
    border: none;
    border-radius: 4px;
    outline: none;
  }

  .send-button {
    margin-left: 10px;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    outline: none;
  }

  .send-button:hover {
    background-color: #0056b3;
  }

  .ai-reply {
    text-align: left;
    margin-left: 5px; /* Adjust the margin as needed */
    padding-left: 10px;
  }
</style>
